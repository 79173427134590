import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connectHits, SearchBox } from "react-instantsearch-dom";
import { Index, InstantSearch } from "react-instantsearch-core";

import { GridContainer, StandardLayoutContainer } from "@util/standard";
import { isBrowser, searchClient } from "@util/helper";
import { Maybe, SanityBrand, SanityNews, SanityRange } from "@graphql-types";
import { isSanityBrand, isSanityNews, isSanityRange } from "@util/types";
import { useStore } from "@util/states";
import PreviewCard from "@components/search/previewCard";
import Layout from "@shared/layout";

interface HitsProps {
  hits: Maybe<SanityNews | SanityRange | SanityBrand>[];
}

const IndexName = styled.h3`
  margin-top: 100px;
  font-size: 25px;
`;
const HiddenSearchBox = styled(SearchBox)`
  display: none;
`;

const SearchHits = ({ hits }: HitsProps) => {
  if (hits == null || hits.length === 0) return <h3>No Results</h3>;

  const { currentRegion } = useStore();

  return (
    <GridContainer width="100%" margin="20px auto 100px auto" repeat={3} columnGap={20} rowGap={50}>
      {hits.map(hit => {
        if (hit == null) return null;
        if (
          hit.region?.iban !== currentRegion.iban &&
          hit.region?.iban !== process.env.GATSBY_DEFAULT_IBAN_REGION
        )
          return null;

        const attrDefaults = {
          key: hit._id,
          _type: hit._type,
          title: hit.title,
          slug: hit.slug,
          region: hit.region,
        };

        if (isSanityBrand(hit)) {
          return <PreviewCard {...attrDefaults} image={hit.coverImage} />;
        }
        if (isSanityRange(hit)) {
          return <PreviewCard {...attrDefaults} image={hit.imageWithMeta} />;
        }
        if (isSanityNews(hit)) {
          return <PreviewCard {...attrDefaults} image={hit.gridItemImage} />;
        }
        return null;
      })}
    </GridContainer>
  );
};

const CustomSearchHits = connectHits(SearchHits);

function SearchResults() {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (location.search) {
      const urlSearch = location.search.substring(1);
      setSearchValue(decodeURI(urlSearch));
    }
  }, [isBrowser() && location.search]);

  return (
    <Layout headerOverride="transparentDarkText">
      <StandardLayoutContainer
        flexDirection="column"
        margin="250px auto 100px auto"
        tabletMargin="150px auto 100px auto"
      >
        <h1>Search results for</h1>
        <h1>"{searchValue ?? "No Results Found"}"</h1>

        <InstantSearch indexName="brands" searchClient={searchClient}>
          <HiddenSearchBox defaultRefinement={searchValue} />

          <Index indexName="brands">
            <IndexName>Brands</IndexName>
            <CustomSearchHits />
          </Index>

          <Index indexName="ranges">
            <IndexName>Ranges</IndexName>

            <CustomSearchHits />
          </Index>

          <Index indexName="news">
            <IndexName>News</IndexName>
            <CustomSearchHits />
          </Index>
        </InstantSearch>
      </StandardLayoutContainer>
    </Layout>
  );
}

export default SearchResults;
