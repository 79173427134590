import React from "react";
import styled from "styled-components";

import { Link, Image } from "@global";
import { Maybe, SanityImageWithMeta, SanitySlug, SanityRegion } from "@graphql-types";
import { colors } from "@util/constants";
import { Container } from "@util/standard";

interface PreviewCardProps {
  title: Maybe<string> | undefined;
  image: Maybe<SanityImageWithMeta> | undefined;
  slug: Maybe<SanitySlug> | undefined;
  region: Maybe<SanityRegion> | undefined;
  _type: Maybe<string> | undefined;
}

const StyledLink = styled(Link)`
  height: 100%;
  div {
    text-decoration: none;
    h4 {
      font-size: 25px;
      margin-top: 0px;
    }
    p {
      text-transform: capitalize;
      font-size: 15px;
      margin-top: 20px;
      color: ${colors.grey};
    }
    &:visited {
    }
  }
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
`;

const ImageWrapper = styled(Container)`
  height: 40vh;
  width: 100%;

  .gatsby-image-wrapper {
    border-radius: 25px;
  }
`;

const PreviewCard = ({ title, image, slug, region, _type }: PreviewCardProps) => {
  return (
    <StyledLink internalLink={{ slug, region, _type }}>
      <Container flexDirection="column" height="100%">
        {image && (
          <ImageWrapper>
            <Image data={image} objectFit="cover" width="100%" />
          </ImageWrapper>
        )}
        <p>{_type}</p>
        <h4>{title}</h4>
      </Container>
    </StyledLink>
  );
};

export default PreviewCard;
